/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.spinner-border-lg.spinner-border {
	width: 3.5rem;
	height: 3.5rem;
}

.react-flow__attribution {
	display: none;
}

[dir] .react-dataTable {
	overflow-x: unset;
	overflow-y: unset;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-logo img {
	max-width: 130px !important;
}

.bg-light-success .fc-daygrid-event-dot {
	border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, rgba(40, 199, 111, 1));
}

.bg-light-warning .fc-daygrid-event-dot {
	border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, rgba(255, 159, 67, 1));
}

.bg-light-primary .fc-daygrid-event-dot {
	border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, rgba(0, 93, 33, 1));
}

.new-notification {
	// background-color: lightgray;
	padding: 5px;
}

.new-notification .media-heading {
	position: relative;
}

.new-notification .media-heading::before {
	position: absolute;
	content: '';
	width: 8px;
	height: 8px;
	background: rgb(var(--bs-danger-rgb));
	border-radius: 50%;
	margin-top: 5px;
	margin-left: -10px;
}

.new-notification p {
	font-weight: 800;
	color: black !important;
}

.new-notification small b {
	font-weight: 800 !important;
	font-size: 13px;
}

.new-notification small.date {
	font-weight: 800 !important;
	font-size: 13px;
}

.digitGroup {
	display: flex;
	gap: 4px;
	justify-content: flex-start;
	margin-bottom: 20px;
}

.digitGroup input {
	outline: 0 !important;
	user-select: none !important;
	width: 22px;
	height: 20px;
	/* background-color: rgba(0, 93, 33, 0.22); */
	font-weight: normal !important;
	border-radius: 0px;
	border: 1px solid #005d21;
	line-height: 20px;
	text-align: center;
	font-size: 13px;
	color: #005d21;
	margin: 0;
}

.digitGroup input:focus {
	border: 1px solid #005d21 !important;
}

.digitGroup input:active {
	border: 2px solid #005d21 !important;
}

.btnGroup {
	text-align: center;
}

.digitGroup1 {
	display: flex;
	gap: 8px;
	justify-content: center;
	margin-bottom: 20px;
}

.digitGroup1 input {
	outline: 0 !important;
	user-select: none !important;
	width: 50px;
	height: 50px;
	/* background-color: rgba(0, 93, 33, 0.22); */
	font-weight: 700 !important;
	border-radius: 3px;
	border: 1px solid #005d21;
	line-height: 30px;
	text-align: center;
	font-size: 22px;
	color: #005d21;
	margin: 0;
}

.digitGroup1 input:focus {
	border: 1px solid #005d21 !important;
}

.digitGroup1 input:active {
	border: 2px solid #005d21 !important;
}

/* Media query for mobile devices */
@media (max-width: 480px) {
	.digitGroup {
		gap: 5px !important;
	}

	.digitGroup input {
		width: 40px !important;
	}

	.digitGroup {
		gap: 5px !important;
	}

	.digitGroup1 input {
		width: 35px !important;
		height: 35px !important;
		font-size: 18px;
	}
}


.invalidText {
	border: 1px solid #ea5455 !important;
}

.select__menu {
	z-index: 999999 !important;
}

//   Accordion Css
.rc-accordion-toggle {
	display: flex;
	align-items: flex-start;
	cursor: pointer;
	justify-content: space-between;
	background-color: #ebedf0;
	transition: 0.1s;
}

.rc-accordion-toggle.active {
	background-color: #005d21;
}

.rc-accordion-toggle.active .rc-accordion-icon {
	transform: rotate(180deg);
	color: #fff;
}

.rc-accordion-card {
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-bottom: 10px;
	// overflow: hidden;
}

.rc-accordion-card:last-child {
	margin-bottom: 0;
}

.rc-accordion-title {
	font-weight: 500;
	font-size: 16px;
	position: relative;
	margin-bottom: 0;
	color: #475F7B;
	transition: 0.3s;
}

.rc-accordion-toggle.active .rc-accordion-title {
	color: #fff;
}

.rc-accordion-icon {
	position: relative;
	top: 2px;
	color: #475F7B;
	transition: 0.35s;
	font-size: 12px;
}

.rc-accordion-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 15px;
}

.rc-accordion-body p {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #727E8C;
}

.rc-collapse {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}

.rc-collapse.show {
	height: auto;
	overflow: visible;
}

.dataTable-container {
	overflow-x: auto;
	white-space: nowrap;
}

.dataTable-header {
	position: sticky;
	top: 0;
	background-color: white;
	/* or your preferred background color */
	z-index: 1;
	/* Ensure the header stays above the table rows */
}

.dataTable-wrapper {
	display: flex;
	flex-direction: column;

}